import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Quicklinks = ({ title, links, className, smoothScroll }) => {
  const getClassnames = () => classNames('quicklinks', className);

  const clickHandler = (e, target, clickTarget) => {
    if (smoothScroll) {
      const el = document.querySelector(target);
      if (el) {
        e.preventDefault();
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (clickTarget) {
      document.querySelector(clickTarget).click();
    }
  };

  return (
    <ul className={getClassnames()}>
      {title && (
        <li className="quicklinks__title">
          <span>{title}</span>
        </li>
      )}
      {links.map(
        ({ target, label, listItemClass, clickTarget, ...rest }, index) => (
          <div key={index} className="quicklinks__divider-and-link">
            {index === 0 ? '' : <span className="quicklinks__divider"></span>}
            <li
              key={`${target}-${label}`}
              className={classNames('quicklinks__link', listItemClass)}
            >
              <a
                href={target}
                onClick={(e) => clickHandler(e, target, clickTarget)}
                {...rest}
              >
                {label}
              </a>
            </li>
          </div>
        )
      )}
    </ul>
  );
};

Quicklinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      listItemClass: PropTypes.string,
      /** Clicks the element with the given target */
      clickTarget: PropTypes.bool,
    })
  ).isRequired,
  smoothScroll: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
};

Quicklinks.defaultProps = {
  smoothScroll: false,
  title: 'Quick Links:',
  links: {
    clickTarget: null,
  },
};

export default Quicklinks;
